/* You can add global styles to this file, and also import other style files */

.table.vertical-align-center > tbody > tr > td {
  vertical-align: middle;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.dialog-container-lg {
  nb-dialog-container {
    min-width: 700px;
    max-width: 75vw;
    max-height: 90vh;
    nb-card {
      max-height: 100%;
    }
  }
}

.dialog-container-account-lg {
  nb-dialog-container {
    min-width: 600px;
    max-width: 75vw;
    max-height: 90vh;
    background-color: white;
    nb-card {
      max-height: 100%;
    }
  }
}
